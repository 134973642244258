.navbar {
  background-color: var(--white);
  box-shadow: var(--shadow);
  padding: 0 5%;
  height: 80px;
  position: sticky;
  top: 0;
  z-index: 1;
  justify-content: space-evenly;
  align-items: center;
  z-index: 2;
  transition: visibility 0.5s, opacity 0.5s linear;
}

.hidden {
  visibility: hidden;
  opacity: 0;
}

.white {
  background-color: var(--white);
}

.logo {
  display: none;
}

.brand-text {
  color: var(--blue);
}

.mobile-menu {
  z-index: 3;
  opacity: 0;
  border-radius: 100%;
}

.nav-links {
  justify-content: space-between;
  align-items: center;
  width: 70%;
}

.mobile-nav-link {
  text-decoration: none;
  color: var(--white);
  font-size: 1.3rem;
  opacity: 0;
}

.nav-link {
  text-decoration: none;
  position: relative;
  display: block;
  padding: 5px 0;
  margin: 10px;
  font-size: 1.3rem;
  text-transform: uppercase;
  transition: color 0.1s, background-color 0.1s;
  color: var(--blue);
}

.nav-link:focus,
.nav-link:active {
  color: var(--blue);
}

.nav-link::before {
  content: "";
  display: block;
  position: absolute;
  top: 100%;
  height: 3px;
  width: 100%;
  background-color: var(--blue);
  -webkit-transform-origin: center top;
  transform-origin: center top;
  -webkit-transform: scale(0, 1);
  transform: scale(0, 1);
  transition: color 0.1s, -webkit-transform 0.2s ease-out;
  transition: color 0.1s, transform 0.2s ease-out;
  transition: color 0.1s, transform 0.2s ease-out,
    -webkit-transform 0.2s ease-out;
}

.nav-link:active::before {
  background-color: var(--blue);
}

.nav-link:hover::before,
.nav-link:focus::before {
  -webkit-transform-origin: center top;
  transform-origin: center top;
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
}

.close {
  display: none;
}

.hamburger-icon,
.close-icon {
  cursor: pointer;
  width: 50px;
  height: 50px;
  fill: var(--blue);
}

/*Media queries*/
@media only screen and (max-width: 750px) {
  .navbar {
    justify-content: space-between;
  }

  .logo {
    display: flex;
  }

  .nav-links,
  .open .hamburger-icon {
    display: none;
  }

  .hamburger-menu,
  .hamburger-icon,
  .open .close-icon,
  .open .mobile-nav-links {
    display: flex;
  }

  .hamburger-menu {
    width: 50px;
  }

  .open .mobile-menu {
    display: flex;
    background-color: var(--blue);
    border: 2px solid var(--white);
    z-index: 2;
    position: fixed;
    height: 500px;
    top: 50px;
    left: 0px;
    right: 0px;
    margin: 1.5rem 1rem;
    padding: 2rem;
    border-width: 1px;
    border-radius: 0.5rem;
    opacity: 1;
    transition: border-radius 0.3s ease, visibility 0.3s, opacity 0.3s linear;
  }

  .mobile-nav-links {
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    width: 100%;
  }

  .mobile-nav-link {
    opacity: 1;
    transition: opacity 0.3s linear;
  }

  .mobile-nav-link {
    text-decoration: none;
    position: relative;
    display: block;
    padding: 5px 0;
    margin: 10px;
    font-size: 1.3rem;
    text-transform: uppercase;
    transition: color 0.1s, background-color 0.1s;
  }
}
